import React from 'react';
import { Link, Section, Image } from 'components';
import * as styles from './styles.module.scss';

const LinkBlocksWithImage = ({ data }) => {
  const {
    primary: { title_with_link: title },
    items,
  } = data;

  return (
    <Section className={styles.section} containerClassName={styles.container} whiteBg>
      <div className={styles.titleContainer} dangerouslySetInnerHTML={{ __html: title.html }} />
      <div className={styles.cardsContainer}>
        {items.map((block) => {
          const {
            block_image: blockImage,
            block_label: blockLabel,
            block_title: blockTitle,
            blog_length: blogLength,
            block_link_url: blockLink,
            date,
          } = block;

          return (
            <Link className={styles.card} to={blockLink.url} key={blockTitle.text}>
              <Image className={styles.image} image={blockImage} />
              <div className={styles.cardTexts}>
                <p className={styles.smallDesc}>
                  {date} <span className={styles.dot} /> {blogLength} mins read
                </p>
                <h5>{blockTitle.text}</h5>
                <span className={styles.label}>{blockLabel.text}</span>
              </div>
            </Link>
          );
        })}
      </div>
      <div className={styles.bottomLink}>
        <Link to="https://launchdarkly.com/blog/" withArrow>
          Read more on our blog
        </Link>
      </div>
    </Section>
  );
};
export default LinkBlocksWithImage;
